/* global document grecaptcha */
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Button } from '@andes/button';
import { Form } from '@andes/form';
import { ProgressIndicatorCircular } from '@andes/progress-indicator-circular';
import { Snackbar } from '@andes/snackbar';
import { TextField } from '@andes/textfield';

import Recaptcha from '../recapcha';
import ContactHelper from './helper';

import ClientService from '../../../services/client';
import { useRenderContext } from '../../pages/home/context';

const ContactForm = ({ title, fields, recaptcha, submit, feedback }) => {
  const [performingRequest, setPerformingRequest] = useState(false);
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [mailError, setMailError] = useState('');
  const [messageError, setMessageError] = useState('');
  const [recaptchaError, setRecaptchaError] = useState('hidden');
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [snackBarType, setSnackBarType] = useState('success');
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackBarClass, setSnackBarClass] = useState('hidden');
  const { restClient: restClientContext, siteKey } = useRenderContext();

  const validate = {
    email: ContactHelper.validateEmail,
    message: ContactHelper.validateMessage,
    recaptcha: ContactHelper.validateCaptcha,
  };

  const restClient = new ClientService(restClientContext.baseURL);

  const getUserData = () => {
    const data = {
      email,
      message,
    };
    const recaptchaElement = document.getElementById('g-recaptcha-response');
    if (recaptchaElement) {
      data['g-recaptcha-response'] = recaptchaElement.value;
    }
    return data;
  };

  const showSnackbar = (result) => {
    setFeedbackMessage(feedback[result.status]);
    setSnackBarType(result.status);
    setShowSnackBar(true);
    setSnackBarClass('');
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === 'email') {
      setEmail(value);
      setMailError(validate.email(value).state.mailError);
    } else if (name === 'message') {
      setMessage(value);
      setMessageError(validate.message(value).state.messageError);
    }
  };

  const validateForm = () => {
    const recaptchaElement = document.getElementById('g-recaptcha-response');

    const emailResult = validate.email(email, fields['e-mail'].validations);
    const messageResult = validate.message(message, fields.message.validations);
    const captchaResult = validate.recaptcha(recaptchaElement);

    const result = {
      success: emailResult.success && messageResult.success && captchaResult.success,
      state: { ...emailResult.state, ...messageResult.state, ...captchaResult.state },
    };

    setMailError(result.state.mailError);
    setMessageError(result.state.messageError);
    setRecaptchaError(result.state.recaptchaError);
    return result.success;
  };

  const sendMessage = () => {
    const result = {
      status: 'error',
      state: {
        performingRequest: false,
      },
    };

    setPerformingRequest(true);

    return restClient
      .post(submit.endpoint, { data: getUserData() })
      .then(() => {
        result.status = 'success';
        setEmail('');
        setMessage('');
        setPerformingRequest(true);
        if (window.grecaptcha && window.grecaptcha.reset) {
          grecaptcha.reset();
        }
      }).catch(() => {
        result.status = 'error';
      }).finally(() => {
        setPerformingRequest(false);
        setMailError(result.state.mailError);
        showSnackbar(result);
      });
  };

  const handleLanguage = () => {
    const { country } = recaptcha;
    return country === 'BR' ? 'pt' : 'es';
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (validateForm()) {
      sendMessage();
    }
  };

  return (
    <div>
      <Form
        className="contact-form"
        onSubmit={handleSubmit}
        noValidate
      >
        <h2 className="contact-form__title">{title}</h2>
        <TextField
          className="contact-form__input contact-form__input--email"
          label={`${fields['e-mail'].label}*`}
          id="email-input"
          name="email"
          onChange={handleInputChange}
          modifier={mailError ? 'error' : null}
          helper={mailError}
          value={email}
          required
        />
        <TextField
          className="contact-form__input"
          label={`${fields.message.label}*`}
          id="message-input"
          name="message"
          maxLength={fields.message.validations.max_length.length}
          onChange={handleInputChange}
          multiline
          countdown
          modifier={messageError ? 'error' : null}
          helper={messageError}
          value={message}
          required
        />
        <Recaptcha
          siteKey={siteKey}
          action="SEND_MESSAGE"
          lang={handleLanguage()}
        />
        <div className={`contact-form__recaptcha-validation ${recaptchaError}`}>
          {recaptcha.validations.require}
        </div>
        <Button type="submit">{submit.label}</Button>

        { performingRequest && (
          <ProgressIndicatorCircular
            className="contact-form__spinner"
            label={submit.spinner_label}
            modifier="block"
            size="large"
          />
        )}
      </Form>

      <Snackbar
        className={snackBarClass}
        message={feedbackMessage}
        color={snackBarType === 'success' ? 'green' : 'red'}
        show={showSnackBar}
        delay={3000}
      />
    </div>
  );
};

ContactForm.propTypes = {
  title: PropTypes.string.isRequired,
  fields: PropTypes.shape({
    'e-mail': PropTypes.shape({
      label: PropTypes.string,
      validations: PropTypes.shape({
        valid: PropTypes.string,
        require: PropTypes.string,
      }),
    }),
    message: PropTypes.shape({
      label: PropTypes.string,
      validations: PropTypes.shape({
        require: PropTypes.string,
        max_length: PropTypes.shape({
          message: PropTypes.string,
          length: PropTypes.number,
        }),
      }),
    }),
  }).isRequired,
  recaptcha: PropTypes.shape({
    country: PropTypes.string,
    validations: PropTypes.shape({
      require: PropTypes.string,
    }),
  }).isRequired,
  submit: PropTypes.shape({
    label: PropTypes.string,
    spinner_label: PropTypes.string,
    endpoint: PropTypes.string,
  }).isRequired,
  feedback: PropTypes.shape({
    success: PropTypes.string,
    error: PropTypes.string,
  }).isRequired,
};

export default ContactForm;
