import React from 'react';
import { string } from 'prop-types';
import { Head } from 'nordic/head';

const Recaptcha = ({
  siteKey,
  cspNonce = '',
  lang = 'es',
}) => {
  const overwriteStyles = {
    grecaptcha: {
      transform: 'scale(0.89)',
      transformOrigin: '0 0',
    },
    container: {
      margin: '30px 0',
      position: 'relative',
    },
  };

  const url = `https://www.google.com/recaptcha/api.js?hl=${lang}&render=explicit async defer`;

  function loadRecaptcha(src) {
    return new Promise((resolve) => {
      const recaptchaScript = document.createElement('script');
      recaptchaScript.src = src;
      recaptchaScript.defer = true;
      recaptchaScript.async = true;
      recaptchaScript.onload = resolve;
      document.head.appendChild(recaptchaScript);
    });
  }

  if (typeof document !== 'undefined') {
    loadRecaptcha(url);
  }

  const markup = () => (
    <div>
      <div
        id="g-recaptcha"
        className="g-recaptcha"
        data-sitekey={siteKey}
        style={overwriteStyles.grecaptcha}
      />
    </div>
  );

  return (
    <div style={overwriteStyles.container} className="recaptcha-container">
      <Head>
        <link rel="preconnect" href="https://www.gstatic.com" />
        <link nonce={cspNonce} rel="preload" href={url} as="script" />
      </Head>
      { markup() }
    </div>
  );
};

Recaptcha.propTypes = {
  siteKey: string.isRequired,
  cspNonce: string,
};
export default Recaptcha;
