const ValidationService = require('../../../services/validation');

class ContactHelper {
  static validateEmail(email, validations = { require: '', valid: '' }) {
    const result = (success, { mailError = '', mailState = '' }) => ({
      success,
      state: { mailError, mailState },
    });

    if (ValidationService.isEmpty(email.trim())) {
      return result(false, { mailError: validations.require, mailState: 'error' });
    }
    if (!ValidationService.isValidEmail(email)) {
      return result(false, { mailError: validations.valid, mailState: 'error' });
    }
    return result(true, { mailState: 'completed' });
  }

  static validateMessage(message, validations = { require: '' }) {
    const valid = !ValidationService.isEmpty(message.trim());
    return {
      success: valid,
      state: {
        messageError: valid ? '' : validations.require,
      },
    };
  }

  static validateCaptcha(recaptcha) {
    const valid = !ValidationService.isEmpty(recaptcha ? recaptcha.value : '');
    return {
      success: valid,
      state: {
        recaptchaError: valid ? 'hidden' : '',
      },
    };
  }
}

export default ContactHelper;
